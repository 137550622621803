import React from 'react'
import Header from '../components/Header'

export default function NoPage(){
  return (
    <>
    <Header />
    <h1 className="NoPage">Error 404: Not Found</h1>
    </>
  )
}
