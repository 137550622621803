import React from 'react'
import Header from '../components/Header'


export default function Home(){
  return (
    <>
    <Header />
    <h1 className="Home">HOME</h1>

    </>
  )
}


